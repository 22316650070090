import {
  Box,
  Flex,
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Alert, LoadingIndicator } from "../../../components";
import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  hideIntercomButton,
  showIntercomButton,
} from "../../../utils/intercom";
import {
  CandidateInterviewFragment,
  useCandidatePositionHighlightsLazyQuery,
  useCandidatePositionInterviewsLazyQuery,
  useCandidateQuery,
  useCandidateScoresLazyQuery,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { useIsExtension } from "../../hooks/useAppEnvironmentContext";
import useCurrentUser from "../../hooks/useCurrentUser";
import useSetPageTitle from "../../hooks/useSetPageTitle";
import Forbidden from "../../pages/forbidden/Forbidden";
import { CandidateAsk } from "./Ask/CandidateAsk";
import { CandidateAskV2 } from "./Ask/CandidateAskV2";
import { useCandidateAskEnabled } from "./Ask/useCandidateAskEnabled";
import CandidateHeader from "./CandidateHeader/CandidateHeader";
import CompareTab from "./compare/CompareTab";
import { EmptyInterviewState } from "./EmptyState";
import HighlightModal from "./HighlightModal";
import HighlightModalMobile from "./HighlightModalMobile";
import HighlightsTab from "./HighlightsTab";
import InterviewTab from "./InterviewTab";
import ScoresTab from "./scores/ScoresTab";
import SummaryTab from "./summary/SummaryTab";
import { useShowCandidateSummary } from "./summary/useCanViewCandidateSummary";
import { useAiTagsGeneration } from "./useAiTagsGeneration";
import { useHighlightFilters } from "./useHighlightFilters";
import { useHighlightModal } from "./useHighlightModal";
import { hasError, mergeHighlightsWithCalls } from "./utils";

const defaultTab = "interviews";

const buildTabList = (
  showCandidateCompare: boolean,
  showCandidateScores: boolean,
  showCandidateSummary: boolean
): string[] => {
  const tabs = ["interviews", "highlights"];
  if (showCandidateCompare) tabs.push("compare");
  if (showCandidateScores) tabs.push("scores");
  if (showCandidateSummary) tabs.splice(1, 0, "debrief");
  return tabs;
};

const useTabMap = (
  showCandidateCompare: boolean,
  showCandidateScores: boolean,
  showCandidateSummary: boolean
): { [idx: number]: string } => {
  const tabs = buildTabList(
    showCandidateCompare,
    showCandidateScores,
    showCandidateSummary
  );
  return tabs.reduce<{ [idx: number]: string }>((currentMap, item, idx) => {
    return { ...currentMap, [idx]: item };
  }, {});
};

const useReverseTabMap = (
  showCandidateCompare: boolean,
  showCandidateScores: boolean,
  showCandidateSummary: boolean
): { [idx: string]: number } => {
  const tabs = buildTabList(
    showCandidateCompare,
    showCandidateScores,
    showCandidateSummary
  );
  return tabs.reduce<{ [idx: string]: number }>((currentMap, item, idx) => {
    return { ...currentMap, [item]: idx };
  }, {});
};

const CandidateView: React.FC = () => {
  const sendGAEvent = useSendGAEvent();
  const isAskEnabled = useCandidateAskEnabled();
  const isAskV2Enabled = useFeatureFlag("candidate:ask:v2");
  const [isAskOpen, setIsAskOpen] = useState(false);
  const { pathname } = useLocation();
  const { layoutHeight } = useWindowDimensions();
  const isExtension = useIsExtension();
  const isSmallScreen = useIsSmallScreen();
  const currentUser = useCurrentUser();
  const { allowAiNotes, scoringEnabled } = currentUser.organization;
  const showCandidateCompare = !isSmallScreen && allowAiNotes;

  const { candidateId, positionId } = useParams() as {
    candidateId: string;
    positionId?: string;
  };

  const [getScores, scoresQuery] = useCandidateScoresLazyQuery({
    variables: { candidateId, positionId },
  });
  useEffect(() => {
    if (scoringEnabled) getScores();
  }, [scoringEnabled]);
  const scorecards = scoresQuery.data?.candidate?.scorecardsByPosition;
  const showCandidateScores = !!scorecards && scorecards.length > 0;

  const showCandidateSummary = useShowCandidateSummary();

  // Needed to dynamically generate the tab lookups based on their visibility
  const tabMap = useTabMap(
    showCandidateCompare,
    showCandidateScores,
    showCandidateSummary
  );
  const reverseTabMap = useReverseTabMap(
    showCandidateCompare,
    showCandidateScores,
    showCandidateSummary
  );

  useEffect(() => {
    if (isAskOpen) {
      hideIntercomButton();
    } else {
      showIntercomButton();
    }
  }, [isAskOpen]);

  /**
   * Set initial tab based on URL search, update URL search
   * on tab change to keep the same tab on page refresh
   */
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIdx = reverseTabMap[searchParams.get("tab") || defaultTab] || 0;
  const curTab = tabMap[tabIdx];
  const onTabChange = (newTabIdx: number): void => {
    const tab = tabMap[newTabIdx] || defaultTab;
    searchParams.set("tab", tab);
    searchParams.delete("templateId");
    setSearchParams(searchParams, { replace: true });
    sendGAEvent(
      isExtension ? "extension_tab_change" : "tab_change",
      "candidate",
      tab
    );
  };

  const currentUserNotesOnly =
    currentUser.organization.hideOtherInterviewerNotesInExtension &&
    isExtension;

  const {
    data,
    loading: candidateLoading,
    error,
  } = useCandidateQuery({ variables: { id: candidateId } });
  const candidate = data?.candidate;

  useSetPageTitle({
    prefix: candidate?.fullName ? `${candidate?.fullName} - ` : "",
  });

  const [
    getCandidatePositionInterviews,
    {
      data: positionInterviewData,
      loading: positionInterviewsLoading,
      error: positionInterviewsError,
    },
  ] = useCandidatePositionInterviewsLazyQuery();

  const [
    getCandidatePositionHighlights,
    {
      data: highlightsData,
      loading: highlightsLoading,
      refetch: refetchHighlights,
    },
  ] = useCandidatePositionHighlightsLazyQuery();
  const highlights = highlightsData?.candidate?.callsByPosition || [];

  const awaitingRedirect = pathname.endsWith(candidateId);
  useEffect(() => {
    if (!awaitingRedirect) {
      getCandidatePositionInterviews({
        variables: {
          id: candidateId,
          positionId,
          currentUserOnly: currentUserNotesOnly,
        },
      });
      getCandidatePositionHighlights({
        variables: {
          id: candidateId,
          positionId,
          currentUserOnly: currentUserNotesOnly,
        },
      });
    }
  }, [awaitingRedirect, candidateId, positionId]);

  const { tagsGenerating } = useAiTagsGeneration({
    callIds: (highlights ?? []).map((h) => h.id),
    shouldFetchTags: curTab === "highlights",
    refetchTags: () => {
      if (!awaitingRedirect) {
        refetchHighlights({
          id: candidateId,
          positionId,
          currentUserOnly: currentUserNotesOnly,
        });
      }
    },
  });

  const candidateCalls = mergeHighlightsWithCalls(
    highlights,
    positionInterviewData?.candidate?.callsByPosition
  );
  const scheduledInterviews =
    positionInterviewData?.candidate?.scheduledInterviewsByPosition;

  const isHighlightsTab = searchParams.get("tab") === "highlights";
  const { showHighlightsForInterview, allHighlights, ...highlightsFilters } =
    useHighlightFilters(candidateCalls ?? []);

  const {
    activeHighlight,
    setActiveHighlight,
    goToPrevHighlight,
    goToNextHighlight,
  } = useHighlightModal(
    isHighlightsTab ? highlightsFilters.matchingHighlights : allHighlights
  );

  const renderError = (): JSX.Element => {
    if (hasError(error, "FORBIDDEN")) {
      sendGAEvent("page_error", "candidate", "forbidden");
      return <Forbidden showLayout={false} forbiddenFrom="candidate" />;
    }
    if (hasError(error, "NOT_FOUND")) {
      sendGAEvent("page_error", "candidate", "not_found");
      return <Navigate to="/not-found" replace />;
    }

    sendGAEvent("page_error", "candidate", "unknown");
    return (
      <Alert status="error" description="Error loading candidate" reload />
    );
  };
  if (candidateCalls) {
    candidateCalls.sort(
      (a: CandidateInterviewFragment, b: CandidateInterviewFragment) => {
        return (
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
        );
      }
    );
  }

  const loading = candidateLoading || positionInterviewsLoading;
  const hasCallsForPosition =
    awaitingRedirect ||
    positionInterviewsLoading ||
    !candidateCalls ||
    !scheduledInterviews
      ? true
      : candidateCalls.length + scheduledInterviews.length > 0;

  if (!loading && !error && candidate && !hasCallsForPosition) {
    return (
      <Flex
        direction="column"
        bg="rgba(234, 249, 255, 0.3);"
        minH={layoutHeight}
        pb="8"
      >
        <Box
          bg="white"
          px={{ base: "4", lg: "8" }}
          pt={{ base: "4", lg: "7" }}
          pb="8"
        >
          <CandidateHeader candidate={candidate} positionId={positionId} />
        </Box>
        <Flex justifyContent="center" textAlign="center">
          <EmptyInterviewState />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex>
      <Grid
        gridTemplateRows="auto 1fr"
        bg="white"
        minH={layoutHeight}
        px={{ base: "4", lg: "8" }}
        pt={{ base: "4", lg: "7" }}
        pb="8"
        gap="6"
        flexGrow="1"
      >
        {!loading && error && renderError()}
        {!candidateLoading && !error && candidate && (
          <CandidateHeader
            candidate={candidate}
            positionId={positionId}
            isAskEnabled={isAskEnabled}
            onAskClick={() => {
              setIsAskOpen(!isAskOpen);
            }}
          />
        )}
        {loading && <LoadingIndicator position="sticky" top="35%" />}
        {!loading && !error && candidate && (
          <Tabs
            index={tabIdx}
            onChange={onTabChange}
            isLazy
            display="grid"
            gridTemplateRows="auto 1fr"
          >
            <TabList>
              <Tab data-tour-id="candidate-page-tab-interviews">
                Interviews{" "}
                {!positionInterviewsLoading &&
                  `(${
                    (candidateCalls ?? []).length +
                    (scheduledInterviews ?? []).length
                  })`}
              </Tab>
              {showCandidateSummary && (
                <Tab data-tour-id="candidate-page-tab-summary">Debrief</Tab>
              )}
              <Tab data-tour-id="candidate-page-tab-highlights">
                {!highlightsLoading && `Highlights (${allHighlights.length})`}
                {highlightsLoading && (
                  <>
                    Highlights <LoadingIndicator size="xs" ml="2.5" />
                  </>
                )}
              </Tab>
              {showCandidateCompare && (
                <Tab data-tour-id="candidate-page-tab-compare">Compare</Tab>
              )}
              {showCandidateScores && (
                <Tab data-tour-id="candidate-page-tab-scores">Scores</Tab>
              )}
            </TabList>

            <TabPanels display="grid">
              <TabPanel mt="6">
                <InterviewTab
                  calls={candidateCalls || []}
                  scheduledInterviews={scheduledInterviews ?? []}
                  currentUserNotesOnly={currentUserNotesOnly}
                  showHighlightsForInterview={showHighlightsForInterview}
                />
              </TabPanel>

              {showCandidateSummary && (
                <TabPanel>
                  <SummaryTab
                    candidateId={candidateId}
                    positionId={positionId}
                  />
                </TabPanel>
              )}

              <TabPanel>
                <HighlightsTab
                  filterState={highlightsFilters}
                  totalHighlights={allHighlights.length}
                  loading={highlightsLoading}
                  error={positionInterviewsError}
                  smartFiltersGenerating={!!tagsGenerating}
                  setActiveHighlight={setActiveHighlight}
                />
              </TabPanel>

              {showCandidateCompare && (
                <TabPanel display="grid">
                  <CompareTab
                    candidateId={candidateId}
                    candidateName={candidate?.fullName}
                    positionId={positionId}
                  />
                </TabPanel>
              )}
              {showCandidateScores && (
                <TabPanel>
                  <ScoresTab scorecards={scorecards} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        )}
        {activeHighlight &&
          (isSmallScreen ? (
            <HighlightModalMobile
              highlight={activeHighlight}
              onClose={() => setActiveHighlight(null)}
            />
          ) : (
            <HighlightModal
              highlight={activeHighlight}
              onClose={() => setActiveHighlight(null)}
              onGoForward={isHighlightsTab ? goToNextHighlight : undefined}
              onGoBack={isHighlightsTab ? goToPrevHighlight : undefined}
              tooltipLabels={["Previous highlight", "Next highlight"]}
            />
          ))}
        {!loading && !error && !candidate && (
          <Navigate to="/not-found" replace />
        )}
      </Grid>
      {!isExtension && !isSmallScreen && isAskEnabled && isAskOpen && (
        <Flex width="450px" height="100vh" position="sticky" top="0" bg="white">
          {isAskV2Enabled ? (
            <CandidateAskV2
              candidateId={candidateId}
              onClose={() => {
                setIsAskOpen(false);
              }}
            />
          ) : (
            <CandidateAsk
              candidateId={candidateId}
              onClose={() => {
                setIsAskOpen(false);
              }}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default CandidateView;
